export const REQUEST_REGISTRAR_GET_COST = 'REQUEST_REGISTRAR_GET_COST';
export const RECEIVE_REGISTRAR_GET_COST = 'RECEIVE_REGISTRAR_GET_COST';
export const REQUEST_REGISTRAR_COMMIT = 'REQUEST_REGISTRAR_COMMIT';
export const REQUEST_CHECK_COMMIT_REGISTRAR = 'REQUEST_CHECK_COMMIT_REGISTRAR';
export const ERROR_REGISTRAR_COMMIT = 'ERROR_REGISTRAR_COMMIT';
export const RECEIVE_REGISTRAR_COMMIT = 'RECEIVE_REGISTRAR_COMMIT';
export const REQUEST_REGISTRAR_REVEAL_COMMIT = 'REQUEST_REGISTRAR_REVEAL_COMMIT';
export const RECEIVE_REGISTRAR_REVEAL_COMMIT = 'RECEIVE_REGISTRAR_REVEAL_COMMIT';
export const RECEIVE_CAN_REVEAL_COMMIT = 'RECEIVE_CAN_REVEAL_COMMIT';
export const ERROR_REGISTRAR_REVEAL_COMMIT = 'ERROR_REGISTRAR_REVEAL_COMMIT';
export const OPTIONS_NOT_FOUND = 'OPTIONS_NOT_FOUND';
export const REGISTRAR_COMMIT_CONFIRMED = 'REGISTRAR_COMMIT_CONFIRMED';
export const REVEAL_COMMIT_CONFIRMED = 'REVEAL_COMMIT_CONFIRMED';
export const RESET_REGISTRAR_STATE = 'RESET_REGISTRAR_STATE';
export const REQUEST_CONVERSION_RATE = 'REQUEST_CONVERSION_RATE';
export const RECEIVE_CONVERSION_RATE = 'RECEIVE_CONVERSION_RATE';
export const ERROR_CONVERSION_RATE = 'ERROR_CONVERSION_RATE';
export const TOGGLE_SETUP_ADDRESS = 'TOGGLE_SETUP_ADDRESS';
export const FIFS_REGISTRER = 'FIFS_REGISTRER';
export const FIFS_ADDR_REGISTRER = 'FIFS_ADDR_REGISTRER';
export const CLOSE_REGISTRATION_ERROR = 'CLOSE_REGISTRATION_ERROR';
