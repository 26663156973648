export const REQUEST_TRANSFER_DOMAIN = 'REQUEST_TRANSFER_DOMAIN';
export const RECEIVE_TRANSFER_DOMAIN = 'RECEIVE_TRANSFER_DOMAIN';
export const ERROR_TRANSFER_DOMAIN = 'ERROR_TRANSFER_DOMAIN';
export const HANDLE_TRANSFER_ERROR_CLOSE = 'HANDLE_TRANSFER_ERROR_CLOSE';
export const HANDLE_TRANSFER_SUCCESS_CLOSE = 'HANDLE_TRANSFER_SUCCESS_CLOSE';

export const REQUEST_DOMAIN_EXPIRATION_TIME = 'REQUEST_DOMAIN_EXPIRATION_TIME';
export const RECIEVE_DOMAIN_EXPIRATION_TIME = 'RECIEVE_DOMAIN_EXPIRATION_TIME';
export const ERROR_DOMAIN_EXIPRATION_TIME = 'ERROR_DOMAIN_EXIPRATION_TIME';
export const TOGGLE_RENEW_PANEL = 'TOGGLE_RENEW_PANEL';

export const REQUEST_RENEW_DOMAIN = 'REQUEST_RENEW_DOMAIN';
export const RECEIVE_RENEW_DOMAIN = 'RECEIVE_RENEW_DOMAIN';
export const ERROR_RENEW_DOMAIN = 'ERROR_RENEW_DOMAIN';
export const CLOSE_RENEW_ERROR_MESSAGE = 'CLOSE_RENEW_ERROR_MESSAGE';
export const CLOSE_SUCCESS_ERROR_MESSAGE = 'CLOSE_SUCCESS_ERROR_MESSAGE';

export const REQUEST_FIFS_MIGRATION = 'REQUEST_FIFS_MIGRATION';
export const RECEIVE_FIFS_MIGRATION = 'RECEIVE_FIFS_MIGRATION';
export const ERROR_FIFS_MIGRATION = 'ERROR_FIFS_MIGRATION';

export const REQUEST_SET_REGISTRY_OWNER = 'REQUEST_SET_REGISTRY_OWNER';
export const RECEIVE_SET_REGISTRY_OWNER = 'RECEIVE_SET_REGISTRY_OWNER';
export const ERROR_SET_REGISTRY_OWNER = 'ERROR_SET_REGISTRY_OWNER';
export const CLOSE_SET_REGISTRY_OWNER = 'CLOSE_SET_REGISTRY_OWNER';

export const REQUEST_RECLAIM_DOMAIN = 'REQUEST_RECLAIM_DOMAIN';
export const ERROR_RECLAIM_DOMAIN = 'ERROR_RECLAIM_DOMAIN';
export const RECEIVE_RECLAIM_DOMAIN = 'RECEIVE_RECLAIM_DOMAIN';

export const NOT_ENOUGH_RIF = 'NOT_ENOUGH_RIF';
