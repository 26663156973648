export const REQUEST_RESOLVE = 'REQUEST_RESOLVE';
export const RECEIVE_RESOLVE = 'RECEIVE_RESOLVE';
export const ERROR_RESOLVE = 'ERROR_RESOLVE';
export const RESET_RESOLVE = 'RESET_RESOLVE';

export const RECEIVE_RESOLVER_ADDRESS = 'RECEIVE_RESOLVER_ADDRESS';
export const RECEIVE_SUPPORTED_INTERFACE = 'RECEIVE_SUPPORTED_INTERFACE';

export const REQUEST_RESOLVE_NAME = 'REQUEST_RESOLVE_NAME';
export const RECEIVE_RESOLVE_NAME = 'RECEIVE_RESOLVE_NAME';
export const ERROR_RESOLVE_NAME = 'ERROR_RESOLVE_NAME';

export const REQUEST_RESOLVE_ADDR = 'REQUEST_RESOLVE_ADDR';
export const RECEIVE_RESOLVE_ADDR = 'RECEIVE_RESOLVE_ADDR';
export const ERROR_RESOLVE_ADDR = 'ERROR_RESOLVE_ADDR';

export const REQUEST_RESOLVE_CHAIN_ADDR = 'REQUEST_RESOLVE_CHAIN_ADDR';
export const RECEIVE_RESOLVE_CHAIN_ADDR = 'RECEIVE_RESOLVE_CHAIN_ADDR';
export const ERROR_RESOLVE_CHAIN_ADDR = 'ERROR_RESOLVE_CHAIN_ADDR';

export const NO_RESOLUTION_FOUND = 'NO_RESOLUTION_FOUND';
export const NO_RESOLVER_ADDRESS = 'NO_RESOLVER_ADDRESS';

export const REQUEST_CONTENT = 'REQUEST_CONTENT';
export const RECEIVE_CONTENT = 'RECEIVE_CONTENT';
export const ERROR_CONTENT = 'ERROR_CONTENT';
