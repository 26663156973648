export const SHOW_AUTH_MODAL = 'SHOW_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
export const TOGGLE_POPUP = 'TOGGLE_POPUP';

export const RECEIVE_HAS_WEB3_PROVIDER = 'RECEIVE_HAS_WEB3_PROVIDER';
export const IS_WALLET_CONNECT = 'IS_WALLET_CONNECT';
export const RECEIVE_HAS_CONTRACTS = 'RECEIVE_HAS_CONTRACTS';

export const REQUEST_ENABLE = 'REQUEST_ENABLE';
export const RECEIVE_ENABLE = 'RECEIVE_ENABLE';
export const ERROR_ENABLE = 'ERROR_ENABLE';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const ERROR_LOGIN = 'ERROR_LOGIN';

export const LOG_OUT = 'LOG_OUT';
