export const REQUEST_NEW_SUBDOMAIN = 'REQUEST_NEW_SUBDOMAIN';
export const WAITING_NEW_SUBDOMAIN_CONFIRM = 'WAITING_NEW_SUBDOMAIN_CONFIRM';
export const RECEIVE_NEW_SUBDOMAIN = 'RECEIVE_NEW_SUBDOMAIN';
export const ERROR_NEW_SUBDOMAIN = 'ERROR_NEW_SUBDOMAIN';
export const ERROR_NEW_SUBDOMAIN_CLOSE = 'ERROR_NEW_SUBDOMAIN_CLOSE';
export const SUCCESS_NEW_SUBDOMAIN_CLOSE = 'SUCCESS_NEW_SUBDOMAIN_CLOSE';

export const CLEAR_SUBDOMAIN_LIST = 'CLEAR_SUBDOMAIN_LIST';
export const ADD_SUBDOMAIN_TO_LIST = 'ADD_SUBDOMAIN_TO_LIST';

export const REQUEST_SET_SUBDOMAIN_OWNER = 'SET_REQUEST_SUBDOMAIN_OWNER';
export const WAITING_SET_SUBDOMAIN_OWNER = 'WAITING_SET_SUBDOMAIN_OWNER';
export const RECEIVE_SET_SUBDOMAIN_OWNER = 'RECEIVE_SET_SUBDOMAIN_OWNER';
export const ERROR_SET_SUBDOMAIN_OWNER = 'ERROR_SET_SUBDOMAIN_OWNER';
export const RECEIEVE_SET_SUBDOMAIN_SUCCESS_CLOSE = 'RECEIEVE_SET_SUBDOMAIN_SUCCESS_CLOSE';

export const REMOVE_SUBDOMAIN_FROM_LIST = 'REMOVE_SUBDOMAIN_FROM_LIST';
