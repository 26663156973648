export const SET_VIEW = 'SET_VIEW';

export const CHECK_IF_SUBDOMAIN = 'CHECK_IF_SUBDOMAIN';
export const REQUEST_CHECK_OWNERSHIP = 'REQUEST_CHECK_OWNERSHIP';
export const RECIEVE_CHECK_OWNERSHIP = 'RECIEVE_CHECK_OWNERSHIP';
export const ERROR_CHECK_OWNERSHIP = 'ERROR_CHECK_OWNERSHIP';

export const REQUEST_FIFS_MIGRATION_STATUS = 'REQUEST_FIFS_MIGRATION_STATUS';
export const RECEIVE_FIFS_MIGRATION_STATUS = 'RECEIVE_FIFS_MIGRATION_STATUS';
export const ERROR_FIFS_MIGRATION_STATUS = 'ERROR_FIFS_MIGRATION_STATUS';

export const REQUEST_REGISTRY_OWNER = 'REQUEST_REGISTRY_OWNER';
export const RECEIVE_REGISTRY_OWNER = 'RECEIVE_REGISTRY_OWNER';
export const ERROR_REGISTRY_OWNER = 'ERROR_REGISTRY_OWNER';

export const ERROR_SAME_VALUE = 'ERROR_SAME_VALUE';

export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
